import React from 'react';

import { Button } from 'app/components/Buttons/Button';
import { useAlerts } from 'app/containers/Alerts/Context';
import { AlertAction } from './types';

type AlertActionButtonProps = AlertAction & {
  alertId: string;
};

export function AlertActionButton(props: AlertActionButtonProps) {
  const {
    title,
    type,
    alertId,
    disabled,
    testId,
    valueRequired,
    color,
    loading,
    onClick,
  } = props;

  const { removeAlert } = useAlerts();

  const handleClick = () => {
    onClick();
    removeAlert(alertId);
  };

  switch (type) {
    case 'default':
      return (
        <Button
          variant="contained"
          onClick={handleClick}
          loading={loading}
          disabled={disabled || valueRequired}
          data-test={testId}
          color={color}
        >
          {title}
        </Button>
      );
    case 'secondarySmall':
      return (
        <Button
          size="small"
          color="inherit"
          underline
          onClick={handleClick}
          disabled={disabled}
          data-test={testId}
        >
          {title}
        </Button>
      );
    default:
      return null;
  }
}
